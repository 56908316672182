import {Author} from "./Author.model";

export class Organization {
    constructor(json) {
        this.id = json.id;
        this.profile = new Author(json.profile);
        this.subscription = json.subscription;
        this.createdAt = json.createdAt;
        this.updatedAt = json.updatedAt;
        this.users = json.users.map((a) => new Author(a));
        this.q = this.profile.q + this.users.map((a) => a.q).toString();
    }
}