export class Geofence {
    constructor(json) {
        this.id = json.id;
        this.position = json.position;
        this.notifyEvent = json.notifyEvent;
        this.idleDays = json.idleDays;
        this.initialMessage = json.initialMessage;
        this.subsequentMessage = json.subsequentMessage;
        this.radius = json.radius;
        this.filterId = json.filterId;
        this.q = this.idleDays + ' ' + this.initialMessage + ' ' + this.subsequentMessage + ' ' + this.radius + this.notifyEvent;
    }

    get toCoords() {
        return { lat: this.position.lat, lng: this.position.lon }
    }
}