import baseAPI from "./base-api-service"
import shared from "../shared-config";
import sharedConfig from "../shared-config";
import {Storyspot} from "../models/Storyspot.model";
import {Place} from "../models/Place.model";
import {StorySpotPlace} from "../models/StoryspotPlace.model";

export default {
    getUserStoryspots() {
        return baseAPI
            .getData(shared.api.authEndpoint + shared.api.path.storyspots);
    },
    getOrganzationStoryspots() {
        return baseAPI
            .getData(shared.api.organization.storyspotsEndpoint);
    },
    getStoryspotPlaceById(id) {
        return new Promise((resolve, reject) => {
            baseAPI
                .getData(`${sharedConfig.api.storyspotEndpoint}sp/${id}`)
                .then(resp => {
                    console.log('resp', resp);
                    const storyspot = new Storyspot(resp.data.storyspot);
                    const place = new Place(resp.data.place);
                    const sp = new StorySpotPlace(storyspot, place);
                    resolve(sp);
                })
                .catch(e => {
                    reject(e);
                });
        });
    },
    getSeoStoryspot(id) {
        return new Promise((resolve, reject) => {
            baseAPI
                .getData(`${sharedConfig.api.seoEndpoint}/${id}`)
                .then(resp => {
                    const storyspot = new Storyspot(resp.data);
                    resolve(storyspot);
                })
                .catch(e => {
                    reject(e);
                });
        });
    },
}