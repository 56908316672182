import { convertToDateString } from "../utils/general-utils";

export class Author {
    constructor(json) {
        this.id = json.id;
        this.username = json.username;
        this.fullName = json.fullName;
        this.email = json.email;
        this.picture = json.picture;
        this.description = json.description;
        this.role = json.role;
        this.createdAt = json.createdAt ? new Date(json.createdAt) : null;
        this.socialLinks = json.socialLinks;
        this.q = (json.id + ', ' + json.username + ', ' + json.fullName + ', ' + json.email + ', ' + json.description + ', ' + json.role).toLowerCase();
    }

    get currentDateString() {
        return convertToDateString(this.createdAt);
    }

    get displayName() {
        if (this.fullName) {
            return this.fullName
        } else {
            return this.username;
        }
    }
}
