import sharedConfig from "../../../shared/shared-config";
import verifications from "../../../shared/services/verifications-service"

const state = {
    forgotPassword: {
        successReset: false,
        error: null
    },
    verifyEmail: {
        hasLoaded: false,
        isValidReq: false,
        error: null
    }
}

const getters = {

}

const actions = {
    [sharedConfig.store.verifications.FORGOT_PASSWORD_REQUEST]: (
        { commit, dispatch },
        email
    ) => {
        return new Promise((resolve, reject) => {
            verifications.forgotPassword(email)
                .then(() => {
                    commit(sharedConfig.store.verifications.FORGOT_PASSWORD_SUCCESS);
                    resolve();
                })
                .catch(e => {
                    commit(sharedConfig.store.verifications.FORGOT_PASSWORD_ERROR, e);
                    reject();
                });
        });
    },
    [sharedConfig.store.verifications.VERIFY_EMAIL_REQUEST]: (
        { commit, dispatch },
        token
    ) => {
        verifications.verifyEmail(token)
            .then(() => {
                commit(sharedConfig.store.verifications.VERIFY_EMAIL_SUCCESS);
            })
            .catch(e => {
                commit(sharedConfig.store.verifications.VERIFY_EMAIL_ERROR, e);
            });
    },
}

const mutations = {
    [sharedConfig.store.verifications.FORGOT_PASSWORD_SUCCESS]: state => {
        state.forgotPassword.successReset = true;
        state.forgotPassword.error = "";
    },
    [sharedConfig.store.verifications.FORGOT_PASSWORD_ERROR]: (state, err) => {
        state.forgotPassword.successReset = true;
        state.forgotPassword.error = err;
    },
    [sharedConfig.store.verifications.VERIFY_EMAIL_SUCCESS]: state => {
        state.verifyEmail.hasLoaded = true;
        state.verifyEmail.isValidReq = true;
        state.verifyEmail.error = "";
    },
    [sharedConfig.store.verifications.VERIFY_EMAIL_ERROR]: (state, error) => {
        state.verifyEmail.hasLoaded = true;
        state.verifyEmail.isValidReq = false;
        state.verifyEmail.error = error;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};