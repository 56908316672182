<template>
  <div class="search-container">
    <div class="md-layout">
      <div class="md-layout-item md-small-size-100 md-medium-size-50">
        <h1>{{ title }} <strong>{{ storyspotCount }}</strong></h1>
      </div>
      <div class="search-bar md-layout-item md-small-size-100 md-medium-size-50  md-large-size-40">
        <md-field class="md-form-group">
          <md-icon>{{ searchIcon }}</md-icon>
          <md-input
              v-model="searchText"
              placeholder="Sök..."
              v-on:keyup="didChange"
          ></md-input>
        </md-field>
      </div>
      <div v-if="filter" class="md-layout-item md-small-size-100 md-medium-size-25 md-large-size-15">
        <md-field>
          <label for="SortFilterStoryspot">Sort by</label>
          <md-select v-model="selected" name="filter" id="selected">
            <md-option v-for="option in sortOptions" class="option" :key="option.label" :value="option.label">{{option.label}}</md-option>
          </md-select>
        </md-field>
      </div>
    </div>
  </div>
</template>
<script>

import StoryspotFilter from "./StoryspotFilter";
import sharedConfig from "../shared-config";

export default {
  components: {StoryspotFilter},
  props: {
    searchIcon: {
      type: String,
      default: 'search',
    },
    storyspotCount: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'My storyspots'
    },
    filter: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      searchText: null,
      selected: "Newest first",
      sortOptions: [
        {
          label: 'Newest first',
          value: {
            key: 'createdAt',
            value: 'desc',
            type: 'date'
          }
        },
        {
          label: 'Oldest first',
          value: {
            key: 'createdAt',
            value: 'asc',
            type: 'date'
          }
        },
        {
          label: 'Most likes',
          value: {
            key: 'likes',
            value: 'desc',
            type: 'number'
          }
        },
        {
          label: 'Least likes',
          value: {
            key: 'likes',
            value: 'asc',
            type: 'number'
          }
        },
        {
          label: 'Most listens',
          value: {
            key: 'listens',
            value: 'desc',
            type: 'number'
          }
        },
        {
          label: 'Least listens',
          value: {
            key: 'listens',
            value: 'asc',
            type: 'number'
          }
        }
      ]
    }
  },
  watch: {
    selected(newSort, oldSort) {
      if (newSort !== oldSort) {
        let value = this.sortOptions.filter((a) => a.label == newSort)[0].value;
        console.log('Found value', newSort, value);
        this.didChangeSort(value);
      }
    }
  },
  methods: {
    didChange() {
      this.$emit("send-searchparameter", this.searchText);
    },
    didChangeSort(value) {
      this.$store.dispatch(sharedConfig.store.actions.STORYSPOT.SET_SORT, value);
    },
  }
};
</script>
<style>
hr {
  margin: 5px;
  opacity: 0.5;
}

.search-container {
  margin-bottom: 22px;
}

.search-bar {
  margin: auto;
}

/* Rounded border */
hr.rounded {
  margin-top: 22px !important;
}
.temp {
  color: #bbb;
  border-top: 2px solid #bbb;
  border-radius: 5px;
  margin-bottom: 22px !important;
}

.option {
  margin: 0 25px 0 25px;
}
.md-list-item-text {
  margin: auto;
}
</style>