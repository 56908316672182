export class User {
    constructor(json) {
        this.id = json.id;
        this.fullName = json.fullName;
        this.fbData = json.fbData;
        this.gData = json.gData;
        this.aData = json.aData;
        this.email = json.email;
        this.description = json.description;
        this.isEmailVerified = json.isEmailVerified;
        this.createdAt = json.createdAt;
        this.updatedAt = json.updatedAt;
        this.anonymous = json.anonymous;
        this.username = json.username;
        this.role = json.role;
        this.picture = json.picture;
        this.showEmail = json.showEmail;
        this.socialLinks = json.socialLinks;
        this.countyCode = json.countyCode;
        this._follows = json.follows;
        this._followers = json.followers;
        this._likes = json.likes;
        this.organization = json.organization;
    }
}
