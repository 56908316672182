<template>
  <div class="main-content">
    <div class="wrapper">
      <div class="section">
        <div class="container">
          <div v-if="isVerified">
            <img class="logo" src="../../assets/logo_480.png">
            <h3>Thank you!</h3>
            <h5>Your email has been verified</h5>
            <md-button v-if="isMobile()" class="md-primary open-app" @click="openApp">Open app</md-button>
          </div>
          <div v-else>
            <img class="logo" src="../../assets/logo_480.png">
            <h3>Could not verify</h3>
            <h5>Your email has already been verified</h5>
            <md-button v-if="isMobile()" class="md-primary open-app" @click="openApp">Open app</md-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      isVerified: true
    }
  },
  mounted() {
    if (this.$route.query.success) {
      this.isVerified = false;
    }
  },
  methods: {
    openApp() {
      window.location.href = "storyspot://";
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
  }
});
</script>
<style lang="scss" scoped>
.open-app {
  margin-top: 22px;
  width: 100px;
}

.section {
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}

.logo {
  margin-left: auto;
  margin-right: auto;
  width: 240px;
  height: 240px;
}
</style>