<template>
  <div>
    <div v-if="showPicker" @close="toggleModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div class="modal-header">
                <h1 class="modal-title">Date selection</h1>
                <md-button
                    class="md-simple md-just-icon md-round modal-default-button"
                    @click="toggleModal"
                >
                  <md-icon>clear</md-icon>
                </md-button>
              </div>

              <div class="modal-body text-center">
                <p>Selected date range sets
                  <br>what period the statistics will show</p>
                <br>
                <md-datepicker v-model="fromDate">
                  <label>From date</label>
                </md-datepicker>
                <md-datepicker v-model="toDate">
                  <label>To date</label>
                </md-datepicker>
              </div>

              <div class="modal-footer">
                <md-button class="md-primary md-lg" @click="set">Save</md-button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import sharedConfig from "../../../../shared/shared-config";
import Modal from "../../../../shared/components/Modal"

export default {
  bodyClass: "Period-picker",
  components: {
    Modal
  },
  data() {
    return {
      tempFrom: null,
      tempTo: null
    }
  },
  methods: {
    set() {
       this.$store.dispatch(sharedConfig.settings.DASHBOARD_DAYS, {toDate: this.toDate, fromDate: this.fromDate});
      this.$store.dispatch(sharedConfig.settings.DASHBOARD_TOGGLE_DATEPICKER);
    },
    toggleModal() {
      this.$store.dispatch(sharedConfig.settings.DASHBOARD_TOGGLE_DATEPICKER);
    },
  },
  computed: {
    showPicker() {
      return this.$store.getters.showDatePicker;
    },
    fromDate: {
      get() {
        if (this.tempFrom) {
          return this.tempFrom;
        }
        return this.$store.getters.getDashboardDays.fromDate;
      },
      set(newValue) {
        this.tempFrom = newValue;
      }
    },
    toDate: {
      get() {
        if (this.tempTo) {
          return this.tempTo;
        }
        return this.$store.getters.getDashboardDays.toDate;
      },
      set(newValue) {
        this.tempTo = newValue;

      }
    }
  }
}
</script>
<style scoped>
.md-icon-button {
  background-color: transparent !important;
}
.md-icon-button:hover {
  background-color: transparent !important;
}
</style>

