<template>
  <GmapMap
      v-if="center"
      :center="center"
      :zoom="zoom"
      style="width: 100%; height: 300px"
      :options="mapOptions"
  >
    <gmap-marker v-if="sp && sp.place"
        :key="'map.' + sp.id"
        :position="sp.place.position"
        :icon="icon"
        @click="center = sp.place.position"
    />
    <GmapCircle
        v-if="geofence"
        :key="geofence.id"
        :center="geofence.position"
        :radius="geofence.radius"
        :visible="true"
        :options="{strokeColor: '#299df6', strokeOpacity:0.6,fillColor:'#299df6',fillOpacity:0.3}"
    ></GmapCircle>
  </GmapMap>
</template>
<script>
export default {
  components: {},
  mounted() {
    if (this.sp && this.sp.place && this.sp.place.position) {
      this.center = this.sp.place.position;
    } else if (this.viewPort) {
      this.center = {lat: (this.viewPort.Southwest.lat + this.viewPort.Northeast.lat)/2, lng: (this.viewPort.Southwest.lng + this.viewPort.Northeast.lng)/2} ;
      this.zoom = 13;
  } else if (!this.initCenter) {
      if (!("geolocation" in navigator)) {
        return;
      }
      this.gettingLocation = true;
      navigator.geolocation.getCurrentPosition(
          pos => {
            this.center = {lat: pos.coords.latitude, lng: pos.coords.longitude};

          },
          err => {
          }
      );
    } else {
      this.center = this.initCenter;
      this.zoom = 13;
    }
  },
  props: {
    initCenter: {
      type: Object,
      default: null,
    },
    geofence: {
      type: Object,
      default: null,
    },
    sp: {
      type: Object,
      default: null,
    },
    zoom: {
      type: Number,
      default: 14,
    },
    viewPort: {
      type: Object,
    }
  },
  computed: {
    icon() {
      return {
        path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
        fillColor: this.sp && this.sp.storyspot && this.sp.storyspot.category && this.sp.storyspot.category.color ? this.sp.storyspot.category.color : "#269da8",
        fillOpacity: 1.0,
        strokeWeight: 1,
        scale: 0.5,
        strokeColor: "#ffffff"
      };
    },
  },
  data() {
    return {
      center: null,
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: true,
        styles: [
          {
            featureType: "all",
            elementType: "geometry.fill",
            stylers: [
              {
                weight: "2.00"
              }
            ]
          },
          {
            featureType: "all",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#9c9c9c"
              }
            ]
          },
          {
            featureType: "all",
            elementType: "labels.text",
            stylers: [
              {
                visibility: "on"
              }
            ]
          },
          {
            featureType: "landscape",
            elementType: "all",
            stylers: [
              {
                color: "#f2f2f2"
              }
            ]
          },
          {
            featureType: "landscape",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff"
              }
            ]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff"
              }
            ]
          },
          {
            featureType: "poi",
            elementType: "all",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "all",
            stylers: [
              {
                saturation: -100
              },
              {
                lightness: 45
              }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#eeeeee"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#7b7b7b"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#ffffff"
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "all",
            stylers: [
              {
                visibility: "simplified"
              }
            ]
          },
          {
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "transit",
            elementType: "all",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: [
              {
                color: "#46bcec"
              },
              {
                visibility: "on"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#c8d7d4"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#070707"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#ffffff"
              }
            ]
          }
        ]
      }
    };
  },
}
</script>