<template>
  <md-list-item>
  <a @click="toggleModal" class="md-list-item-router md-list-item-container md-button-clean">
    <div class="md-list-item-content md-ripple">
      <md-icon>event</md-icon>
      <p class="menu-text">{{ diffDays }} days</p>
    </div>
  </a>
  </md-list-item>
</template>
<script>

import sharedConfig from "../../../../shared/shared-config";

export default {
  bodyClass: "Date-picker-icon",
  components: {},
  methods: {
    toggleModal() {
      this.$store.dispatch(sharedConfig.settings.DASHBOARD_TOGGLE_DATEPICKER);
    },
  },
  computed: {
    diffDays() {
      return this.$store.getters.diff;
    },
  }
}
</script>
<style scoped>
.md-icon-button {
  background-color: transparent !important;
}

.md-icon-button:hover {
  background-color: transparent !important;
}

.menu-text {
  color: white !important;
  font-weight: bold !important;
}
</style>

