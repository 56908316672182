import sharedConfig from "../shared-config";

const converter = {
    convertToHash: function(plainText) {
        return btoa(unescape(encodeURIComponent(JSON.stringify(plainText))));
    },
    convertFromHash: function(b64) {
        return JSON.parse(decodeURIComponent(escape(atob(b64))));
    }
};

export default {
    get: function(key) {
        return localStorage.getItem(key) != null
            ? converter.convertFromHash(localStorage.getItem(key))
    : null;
    },
    set: function(key, value) {
        sessionStorage.setItem(key, converter.convertToHash(value));
    },
};
