import sharedConfig from "../shared-config";

const state = {
    audio: null,
    playing: false,
}

const getters = {
    isPlaying(state) {
        return state.playing;
    },
    getPlayerAudio(state) {
        return state.audio;
    },
    getPlayableUrl(state) {
        const url = state.audio && state.audio.url ? state.audio.url : state.audio ? URL.createObjectURL(state.audio) : null;
        return url;
    }
}

const actions = {
    [sharedConfig.store.audioPlayer.SET_AUDIO]: ({commit}, audio) => {
        commit(sharedConfig.store.audioPlayer.SET_AUDIO, audio);
    },
    [sharedConfig.store.audioPlayer.DISMISS_AUDIO]: ({ commit }) => {
        commit(sharedConfig.store.audioPlayer.DISMISS_AUDIO);
    }
}

const mutations = {
    [sharedConfig.store.audioPlayer.SET_AUDIO]: (state, audio) => {
        state.audio = audio;
        console.log('setting', state.audio);
        state.playing = true;
    },
    [sharedConfig.store.audioPlayer.DISMISS_AUDIO]: (state, audio) => {
        state.audio = null;
        state.playing = false;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};