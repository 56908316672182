import sharedConfig from "../shared-config";
import staticService from "../services/static-service";
import {StaticData} from "../models/StaticData.model";

const state = {
    isLoading: false,
    hasLoaded: false,
    staticData: null,
}

const getters = {
    getStaticIsLoading(state) {
        return state.isLoading;
    },
    getStaticHasLoaded(state) {
      return state.hasLoaded;
    },
    getSubscriptions(state) {
        return state.staticData.subscriptions;
    },
    getGeofences(state) {
        return state.staticData.geofence;
    },
    getHasStaticData(state) {
      return state.hasLoaded;
    },
    getLanguages(state) {
        return state.staticData.languages;
    },
    getCategories(state) {
        return state.staticData.categories;
    },
    getStatuses(state) {
        return state.staticData.status
    },
    getSelectableStatuses(state) {
        return state.staticData.status.filter((a) => a.userAllowed);
    },
    getStaticData(state) {
        return state.staticData;
    },
}

const actions = {
    [sharedConfig.store.actions.STATIC.GET_STATIC_DATA_REQUEST]: ({commit}) => {
        commit(sharedConfig.store.actions.STATIC.GET_STATIC_DATA_REQUEST);
        return new Promise((resolve, reject) => {
            staticService.getStaticData().then((resp) => {
                commit(sharedConfig.store.actions.STATIC.GET_STATIC_DATA_SUCCESS, resp.data);
                resolve();
            }).catch((e) => {
                commit(sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOTS_ERROR, e);
                reject();
            });
        })
    },
    [sharedConfig.store.audioPlayer.DISMISS_AUDIO]: ({ commit }) => {
        commit(sharedConfig.store.audioPlayer.DISMISS_AUDIO);
    }
}

const mutations = {
    [sharedConfig.store.actions.STATIC.GET_STATIC_DATA_REQUEST]: (state) => {
        state.isLoading = true;
    },
    [sharedConfig.store.actions.STATIC.GET_STATIC_DATA_SUCCESS]: (state, data) => {
        state.staticData = new StaticData(data);
        state.isLoading = false;
        state.hasLoaded = true;
    },
    [sharedConfig.store.actions.STATIC.GET_STATIC_DATA_ERROR]: (state) => {
        state.data = null;
        state.isLoading = false;
        state.hasLoaded = false;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};