import {getDifferenceInDays} from "../../../shared/utils/stat-util";
import sharedConfig from "../../../shared/shared-config";

const currentDate = new Date();
const fromDateInit = new Date(currentDate.getFullYear(), currentDate.getMonth()-1, currentDate.getDate(), 0, 0,0,0);
const toDateInit = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59, 999);

const state = {
    fromDate: fromDateInit,
    toDate: toDateInit,
    showDatePicker: false,
    diff: getDifferenceInDays(toDateInit, fromDateInit).toFixed(0)
}
const getters = {
    getDashboardDays(state) {
        return {
            fromDate: state.fromDate ? state.fromDate : fromDateInit,
            toDate: state.toDate ? state.toDate : toDateInit,
        };
    },
    diff(state) {
        return state.diff;
    },
    showDatePicker(state) {
        return state.showDatePicker;
    }
}

const actions = {
    [sharedConfig.settings.DASHBOARD_DAYS]: (
        { commit, dispatch },
        days
    ) => {
        commit(sharedConfig.settings.DASHBOARD_DAYS_SUCCESS, days);
    },
    [sharedConfig.settings.DASHBOARD_TOGGLE_DATEPICKER]: (
        { commit, dispatch }
    ) => {
        commit(sharedConfig.settings.DASHBOARD_TOGGLE_DATEPICKER_SUCCESS);
    },
}

const mutations = {
    [sharedConfig.settings.DASHBOARD_DAYS_SUCCESS]: (state, days) => {

        if ("fromDate" in days) {
            if (state.fromDate !== days.fromDate) {
                console.log('set fromDate', days);
                state.fromDate = days.fromDate;
            }
        }
        if ("toDate" in days) {
            if (state.toDate !== days.toDate) {
                console.log(days.toDate, typeof days.toDate);
                console.log('set toDate', days);
                state.toDate = days.toDate;
            }
        }
        state.diff = getDifferenceInDays(state.toDate, state.fromDate).toFixed(0)
    },
    [sharedConfig.settings.DASHBOARD_TOGGLE_DATEPICKER_SUCCESS]: (state) => {
        state.showDatePicker = !state.showDatePicker;
        console.log('showDatePicker', state.showDatePicker);
    }
}

export default {
    state,
    getters,
    actions,
    mutations
};