import { validateCord } from "../utils/general-utils";

export class LatLng {
    constructor(json) {
        this.lat = parseFloat(json.lat);
        if (json.lng) this.lng = parseFloat(json.lng);
        else if (json.lon) this.lng = parseFloat(json.lon);
    }

    get validate() {
        return validateCord(this.lat) && validateCord(this.lng);
    }
}
