<template>

</template>
<script>

export default {
  bodyClass: "StoryspotFilter",
  components: {},
  data() {
    return {
      selected: "DatesDown",
    }
  },
  methods: {},
  computed: {}
}
</script>
<style scoped>
.margin {
  margin: 0 8px 0 8px;
}
.option {
  margin: 2px 15px 0 15px;
}
</style>

