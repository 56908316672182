import sharedConfig from "../shared-config";
import organizationApi from "../services/file-service";

const state = {
    files: [],
    hasLoadedFiles: false,
    isLoading: false,
}

const getters = {
    getFiles(state) {
        return state.files;
    },
    hasLoadedFiles(state) {
        return state.files;
    },
}

const actions = {
    [sharedConfig.store.actions.storage.GET_FILES_REQUEST]: ({commit}) => {
        organizationApi.getOrganizationFiles().then((response) => {
            commit(sharedConfig.store.actions.storage.GET_FILES_SUCCESS, response)
        });
    },
    [sharedConfig.store.actions.storage.UPLOAD_FILES_REQUEST]: ({commit, dispatch}, data) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            const {folder, files} = data;
            for (let i = 0; i < files.length; i++) {
                formData.append("files", files[i]);
            }
            if (folder) {
                formData.append("folder", folder);
            }
            organizationApi.uploadOrganizationFiles(formData).then((response) => {
                dispatch(sharedConfig.store.actions.storage.GET_FILES_REQUEST);
                resolve();
            });
        });
    },
    [sharedConfig.store.actions.storage.CREATE_FOLDER]: ({commit, dispatch}, data) => {
        return new Promise(((resolve, reject) => {
            organizationApi.createOrganizationFolder(data.folder).then((response) => {
                dispatch(sharedConfig.store.actions.storage.GET_FILES_REQUEST);
                resolve();
            });
        }))

    },
}

const mutations = {
    [sharedConfig.store.actions.storage.GET_FILES_REQUEST]: (state, response) => {
        state.isLoading = true;
    },
    [sharedConfig.store.actions.storage.GET_FILES_SUCCESS]: (state, response) => {
        state.files = response.data;
        state.isLoading = false;
        state.hasLoadedFiles = true;
    },
    [sharedConfig.store.actions.storage.GET_FILES_ERROR]: (state, audio) => {
        state.files = [];
        state.isLoading = false;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};