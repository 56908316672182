<template>
  <div v-if="isPlaying" class="player-container">
    <audio ref="player" controls  controlsList="nodownload" autoplay class="player">
      <source :src="audio" />
      Your browser does not support the audio element.
    </audio>
  </div>
</template>
<script>
export default {
  watch: {
    audio: function () {
      this.$refs.player.load()
    }
  },
  computed: {
    isPlaying() {
      return this.$store.getters.isPlaying;
    },
    audio() {
     return this.$store.getters.getPlayableUrl;
    },
  },
};
</script>
<style>
.player-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.player {
  margin-top: 12px;
  display: block;
}
@media screen and (max-width: 1024px) {
  .player {
    max-width: 400px;
  }
}

@media screen and (max-width: 480px) {
  .player {
    width: 100%;
  }
}
</style>
