export class StorySpotPlace {

    constructor(storyspot, place) {
        this.storyspot = storyspot;
        this.place = place;
    }

    get id() {
        return this.storyspot.id;
    }
}