<template>
  <ol class="breadcrumb">
    <li class="breadcrumb" v-for="(item, index) in crumbList" :key="item.to">
      <router-link :to="item.to">{{ item.name }}</router-link>
      <p :v-if="isLast(index)"> / </p>
    </li>
  </ol>
</template>
<script>
export default {
  props: {
    crumbs: {
      type: Array,
      default: () => [],
    }
  },
  methods: {
    isLast(index) {
      return index === this.crumbs.length - 1;
    },
  },
  computed: {
    crumbList() {
      return this.crumbs;
    }
  }
}
</script>
<style scoped>

.breadcrumb {
  margin: 25px 0 0 0;
  display: flex;
  align-content: center;
}
.breadcrumb p {
  margin: 0 5px 0 5px;
}
</style>