
export const convertToDateString = function (date) {
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];
    const d = new Date(date);
    const year = d.getFullYear();
    const monthAsText = months[d.getMonth()];
    const day = d.getUTCDate();

    return `${day} ${monthAsText} ${year}`;
};

export const getStringDate = function(aDate){
    var dd = aDate;
    var yy = dd.getYear();
    var mm = dd.getMonth() + 1;
    dd = dd.getDate();
    if (yy < 2000) { yy += 1900; }
    if (mm < 10) { mm = "0" + mm; }
    if (dd < 10) { dd = "0" + dd; }
    var rs = yy + "-" + mm + "-" + dd;
    return rs;
}

export const validateCord = function (cord) {
    const val = parseFloat(cord);

    if (!isNaN(val) && val <= 90 && val >= -90) {
        return true;
    } else {
        return false;
    }
};

export const copyString = function (value) {
    const el = document.createElement("textarea");
    el.value = value;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
}

export const shallowEqual = function (value, value2) {
    if (!value && !value2) {
        return true;
    } else if (!value || !value2) {
        return value == value2;
    } else if (typeof value != typeof value2) {
        return false;
    } else if (typeof value != 'object') {
        return value == value2;
    } else if (typeof value == 'object' && typeof value2 == 'object') {
        const keys1 = Object.keys(value);
        const keys2 = Object.keys(value2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys1) {
            // @ts-ignore
            if (value[key] !== value2[key]) {
                return false;
            }
        }

        return true;
    } else {
        return false;
    }
};

export const groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};