export class Status {
    id;
    name;
    userAllowed;

    constructor(json) {
        this.id = json.id;
        this.name = json.name;
        this.userAllowed = json.userAllowed;
    }
}
