import sharedConfig from "../shared-config";

const converter = {
    convertToHash: function(plainText) {
        return btoa(unescape(encodeURIComponent(JSON.stringify(plainText))));
    },
    convertFromHash: function(b64) {
        return JSON.parse(decodeURIComponent(escape(atob(b64))));
    }
};

export default {
    exists: function(key) {
        const value = localStorage.getItem(key);
        return !!value;
    },
    get: function(key) {
        return localStorage.getItem(key) != null
            ? converter.convertFromHash(localStorage.getItem(key))
    : null;
    },
    delete: function(key) {
        localStorage.removeItem(key);
    },
    set: function(key, value) {
        localStorage.setItem(key, converter.convertToHash(value));
    }
};
