<template>
  <div class="tree-menu">
    <div class="folder" :style="indent" @click="toggleChildren" :class="{'selected': selected}">
      {{ title }}
      <md-icon v-if="hasSubfolder && !showChildren" class="menu-icon small">chevron_right</md-icon>
      <md-icon v-if="hasSubfolder && showChildren" class="menu-icon small">expand_more</md-icon>
    </div>
    <tree-menu
        :key="node.displayName"
        v-if="showChildren"
        v-for="node in nodes"
        :nodes="subfolders(node)"
        :displayName="node.displayName"
        :depth="depth + 1"
        :path="path"
        :file="node"
        :folders="folders"
        :onSelect="onSelect"
    >
    </tree-menu>
  </div>
</template>
<script>

export default {
  name: 'tree-menu',
  components: {},
  props: {
    displayName: {
      type: String,
      default: '',
    },
    file: {
      type: Object,
    },
    depth: {
      type: Number,
      default: 0,
    },
    folders: {
      type: Array,
      default: []
    },
    path: {
      type: String,
      default: '/',
    },
    nodes: {
      type: Array,
      default: []
    },
    onSelect: {
      type: Function,
      default: null,
    }
  },
  data() {
    return {}
  },
  methods: {
    toggleChildren() {
      this.onSelect(this.file.directory + this.file.name + '/');
    },
    subfolders(node) {
      return this.folders.filter((a) =>  a.directory.startsWith(this.path) && a.directory.endsWith(node.name + '/')) || [];
    }
  },
  mounted() {
  },
  computed: {
    selected() {
      let filePath = this.file.directory.startsWith('/') ? this.file.directory.substring(1) : this.file.directory;
      return this.path.startsWith(filePath + this.file.name + "/");
    },
    showChildren() {
      let filePath = this.file.directory.startsWith('/') ? this.file.directory.substring(1) : this.file.directory;
      return this.path.startsWith(filePath + this.file.name + "/");
    },
    title() {
      if (this.displayName && this.displayName.length > 30) {
        return this.displayName.substring(0, 35) + '...'
      } else {
        return this.displayName;
      }
    },
    indent() {
      return {transform: `translate(${this.depth * 15}px)`}
    },
    hasSubfolder() {
      return this.file.isFolder;
    }
  },
}
</script>
<style lang="scss" scoped>
.container {
  margin: 0 auto;
}

.folder {
  cursor: pointer;
  padding: 8px;
  margin: 5px 0 5px 0;
  border-radius: 5px;
  font-size: 16px;
  line-height: 23px;
  font-weight: 400;
  user-select: none;
  font-family: 'Inter';
}

.selected {
  background-color: rgb(248, 248, 248);
  //font-weight: bold;
}

.menu-icon {
  float: right;
}

i {
  font-size: 24px !important;;
}
</style>