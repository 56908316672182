import sharedConfig from "../shared-config";
import baseAPI from "../services/base-api-service";

const state = {
    feedback: {
        message: '',
        validation: null,
    },
    support: {
        message: '',
        validation: null,
    }
}

const getters = {
    feedbackMessage(state) {
        return state.feedback.message;
    },
    feedbackValid(state) {
        return state.feedback.validation;
    },
    supportMessage(state) {
        return state.support.message;
    },
    supportValid(state) {
        return state.support.validation;
    }
}

const actions = {
    [sharedConfig.store.other.SET_FEEDBACK]: ({dispatch, commit, state}, message) => {
        state.feedback.message = message;
        state.feedback.validation = message && message.length > 20 ? null : 'Feedback message to short';
    },
    [sharedConfig.store.other.SEND_FEEDBACK]: ({dispatch, commit, state}) => {
        if (state.feedback.validation != null) {
            return;
        }
        return new Promise((resolve, reject) => {
            let data = {
                feedback: state.feedback.message,
            }
            baseAPI
                .postData(sharedConfig.api.authEndpoint + sharedConfig.api.feedback, data)
                .then(resp => {
                    commit(sharedConfig.store.other.SEND_FEEDBACK_SUCCESS);
                    resolve();
                })
                .catch(e => {
                    commit(sharedConfig.store.other.SEND_FEEDBACK_ERROR, e);
                    reject();
                });
        });
    },
    [sharedConfig.store.other.SET_SUPPORT]: ({dispatch, commit, state}, message) => {
        state.support.message = message;
        state.support.validation = message && message.length > 20 ? null : 'Message to short, please describe in detail';
    },
    [sharedConfig.store.other.SET_SUPPORT]: ({dispatch, commit, state}) => {
        if (state.support.validation != null) {
            return;
        }
        return new Promise((resolve, reject) => {
            let data = {
                support: state.support.message,
            }
            baseAPI
                .postData(sharedConfig.api.authEndpoint + sharedConfig.api.feedback, data)
                .then(resp => {
                    commit(sharedConfig.store.other.SEND_FEEDBACK_SUCCESS);
                    resolve();
                })
                .catch(e => {
                    commit(sharedConfig.store.other.SEND_FEEDBACK_ERROR, e);
                    reject();
                });
        });
    },
}

const mutations = {
    [sharedConfig.store.other.SEND_FEEDBACK_SUCCESS]: (state) => {
        console.log('Feedback sent successfully')
        state.feedback.message = '';
        state.feedback.validation = null;
    },
    [sharedConfig.store.other.SEND_FEEDBACK_ERROR]: (state) => {
        console.log('Feedback send error')
    },
    [sharedConfig.store.other.SEND_SUPPORT_SUCCESS]: (state) => {
        console.log('Support sent successfully')
        state.feedback.message = '';
        state.feedback.validation = null;
    },
    [sharedConfig.store.other.SEND_SUPPORT_ERROR]: (state) => {
        console.log('Support send error')
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};