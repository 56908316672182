<template>
  <div>
    <md-card class="main-div">
      <div>
        <div class="md-layout md-gutter">
          <div
              class="md-layout-item md-size-20 md-xlarge-size-20 md-large-size-20 md-small-size-30"
          >

            <img v-if="user.picture"
                :src="user.picture"
                alt="Profile Image"
                class="displayImage"
            />
            <div v-if="user.picture == null" class="no-profile-pic-container">
              <md-icon class="no-profile-pic">face</md-icon>
            </div>
          </div>
          <div
              class="md-layout-item md-size-80 md-xlarge-size-80 md-large-size-80 md-small-size-70 cell-content"
          >
            <div class="text-container">
              <div class="blockquote large-paragraph text-left">
                <small>
                  {{ user.email }}  <strong> • </strong> {{ user.currentDateString }} <strong v-if="isAdmin && showStoryspots"> • </strong> <strong v-if="isAdmin && showStoryspots">{{ counted }} storyspots</strong>
                </small>
              </div>
              <div class="text-left">
              <h4 class="title">
                <router-link v-if="canRedirect" :to="'/users/' + user.id">
                  <span class="tim-note">{{ user.displayName }}</span>
                </router-link>
                <span v-else class="tim-note no-hover">{{ user.displayName }}</span>
              </h4>
              <p class="text-description large-paragraph">
                {{user.description}}
              </p>
              <div class="blockquote undefined sub-menu">
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </md-card>
  </div>
</template>
<script>
import Badge from "../Badge";
import Vue from "vue";
import QrCode from "../QrCode";
import sharedConfig from "../../shared-config"
import {copyString} from "../../utils/general-utils";

export default {
  components: {
    QrCode,
    Badge
  },
  props: {
    user: {
      type: Object,
      default: null
    },
    showStoryspots: {
      default: true,
    },
    canRedirect: {
      default: true,
    }
  },
  data() {
    return {
      searchInput: null,
      showQrModal: false,
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    counted() {
      if (this.$store.getters.hasLoadedStoryspots) {
        return this.$store.getters.getStoryspots.filter((a) => a.author && a.author.id === this.user.id).length;
      }
      return 'Loading...'
    }
  },
  methods: {
    showModal() {
      this.showQrModal = true;
    },
    closeModal() {
      this.showQrModal = false;
    },
    copyLink() {
      let link = "https://app.storyspot.se/share/a" + this.user.id;
      copyString(link);
      const instance = Vue.$toast.open({
        message: "Link copied successfully",
        type: "success",
        position: "top-right"
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.md-card {
  margin: 12px 0 12px 0;
}
.cell-content {
  margin: 30px 0 30px;
}

.blockquote {
  font-size: 18px;
}

.blockquote strong {
  margin: 0 5px 0 5px;
}

.text-container {
  float: left;
  margin-top: 0;
  padding: 0;
}

.text-container .p {
  text-overflow: ellipsis;
}

.title {
  margin-top: 0 !important;
  margin-bottom: 5px !important;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
  color: #3C4858 !important;
}

.md-theme-default a:not(.md-button) {
  color: unset !important;
}


.center {
  margin: auto;
  width: 50%;
}

.icon {
  font-size: 18px !important;
}

.text-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.play-icon {
  position: absolute !important;
  right: 30px;
}


.dropdown-item:hover {
  .md-icon {
    color: white !important;
  }

  p {
    color: white !important;
  }
}

.md-tooltip {
  border-radius: unset;
  color: white !important;
  background-color: #989898 !important;
  font-weight: unset;
  box-shadow: unset;
  max-width: unset;
}

.not-active {
  float: left;
  padding-right: 8px
}

.displayImage {
  margin-left: 0 !important;
  // Just to fill width
  max-height: 156px;
  min-height: 156px;
  border-radius: 6px 0 0 6px;
  object-fit: cover;
}

.no-profile-pic-container {
  display: flex;

  margin-left: 0 !important;
  // Just to fill width
  max-height: 156px;
  min-height: 156px;
  border-radius: 6px 0 0 6px;
}

.no-profile-pic {
  font-size: 68px !important;
  opacity: 0.5;
}

.md-list-item a .material-icons, .md-list-item a .md-icon, .md-list-item a .fab, .md-list-item a .fa {
  font-size: 25px !important;
}

.mini-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  p {
    padding-top: 10px;
  }
}

.no-hover {
  pointer-events: none;
}


</style>