import HTTP from "./axios-default";

export default {
    getData: async function (path) {
        return await HTTP.get(path);
    },
    postData: async function (path, data) {
        return await HTTP.post(path, data);
    },
    putData: async function (
        path,
        data,
    ) {
        return await HTTP.put(path, data);
    },
    postFormData: async function (
        path,
        formData,
        subPath = ""
    ) {
        return await HTTP.post(path, formData, {
            headers: {"Content-Type": "multipart/form-data"}
        });
    },
    putFormData: async function (
        path,
        formData,
        subPath = ""
    ) {
        return await HTTP.put(path, formData, {
            headers: {"Content-Type": "multipart/form-data"}
        }).catch((e) => {
            console.log(e);
        });
    },
};
