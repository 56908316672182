import {Status} from "./Status.model";

export class Language {
    constructor(json) {
        this.id = json.id;
        this.name = json.name;
        this.code = json.code;
        this.nativeName = json.nativeName;
        this.prio = json.prio;
        this.order = json.order;
        this.q = this.name + ' ' + this.code + ' ' + this.nativeName + ' ' + this.id + '' + this.prio + ' ' + this.order
    }
}
