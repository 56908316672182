var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.hasLoaded)?_c('p',[_vm._v("Loading...")]):_vm._e(),(_vm.hasLoaded && _vm.getMarkers != null && _vm.getMarkers.length > 0)?_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"80vh"},attrs:{"center":_vm.center,"zoom":7,"options":{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUI: false,
      mapStyle: { styles: _vm.mapStyle }
    }}},[_c('gmap-info-window',{attrs:{"options":_vm.infoOptions,"position":_vm.infoPosition,"opened":_vm.infoOpened},on:{"closeclick":function($event){_vm.infoOpened=false}}},[(this.selectedSP && this.selectedSP.storyspot)?_c('StoryspotSmall',{attrs:{"storyspot":this.selectedSP.storyspot}}):_vm._e()],1),_vm._l((_vm.getMarkers),function(sp,index){return _c('gmap-marker',{key:index,attrs:{"position":sp.place.position,"icon":_vm.icon(sp)},on:{"click":function($event){return _vm.animateToCords(sp)}}})})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }